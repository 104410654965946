import React from 'react';
import { Users, Target, TrendingUp } from 'lucide-react';

const benefits = [
  {
    icon: Users,
    title: 'Expert Instructors',
    description: 'Learn from experienced healthcare professionals'
  },
  {
    icon: Target,
    title: 'Practical Skills',
    description: 'Gain hands-on experience with real-world applications'
  },
  {
    icon: TrendingUp,
    title: 'Career Growth',
    description: 'Advance your career with recognized certifications'
  }
];

export default function TrainingBenefits() {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Why Choose Our Training</h2>
          <p className="text-xl text-gray-600">
            Invest in your professional development with industry-leading education
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {benefits.map((benefit) => (
            <div key={benefit.title} className="text-center">
              <div className="bg-[#00ccfe]/10 rounded-full p-6 inline-block mb-6">
                <benefit.icon className="h-8 w-8 text-[#00ccfe]" />
              </div>
              <h3 className="text-xl font-semibold mb-4">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}