import React from 'react';
import { Shield, Users, Award } from 'lucide-react';

const values = [
  {
    icon: Shield,
    title: 'Quality First',
    description: 'We deliver only the highest quality products and services to our healthcare partners.'
  },
  {
    icon: Users,
    title: 'Customer Success',
    description: 'Your success is our success. We are committed to supporting your growth.'
  },
  {
    icon: Award,
    title: 'Innovation',
    description: 'Continuously developing solutions that advance healthcare delivery.'
  }
];

export default function AboutSection() {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              About BWell Company
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              BWell Company is dedicated to empowering healthcare providers with innovative solutions 
              that enhance patient care delivery and improve operational efficiency.
            </p>
            <div className="space-y-6">
              {values.map((value) => (
                <div key={value.title} className="flex items-start">
                  <value.icon className="h-6 w-6 text-[#00ccfe] mt-1 mr-4" />
                  <div>
                    <h3 className="text-lg font-semibold mb-1">{value.title}</h3>
                    <p className="text-gray-600">{value.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&w=1000&q=80"
              alt="Healthcare professionals collaborating"
              className="rounded-xl shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}