import React from 'react';
import { Calendar, Users, MessageSquare } from 'lucide-react';

const features = [
  {
    icon: Calendar,
    title: '30-Minute Demo',
    description: 'Comprehensive overview of our solutions tailored to your needs'
  },
  {
    icon: Users,
    title: 'Expert Consultation',
    description: 'Connect with our healthcare solutions specialists'
  },
  {
    icon: MessageSquare,
    title: 'Personalized Plan',
    description: 'Get customized recommendations for your practice'
  }
];

export default function DemoFeatures() {
  return (
    <div className="mt-12 grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
      {features.map((feature) => (
        <div key={feature.title} className="bg-white p-8 rounded-xl shadow-lg text-center">
          <feature.icon className="h-12 w-12 text-[#00ccfe] mx-auto mb-4" />
          <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
          <p className="text-gray-600">{feature.description}</p>
        </div>
      ))}
    </div>
  );
}