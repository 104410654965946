import React from 'react';
import { useNavigate } from 'react-router-dom';

interface DemoButtonProps {
  variant?: 'primary' | 'secondary';
  className?: string;
  children?: React.ReactNode;
}

export default function DemoButton({ 
  variant = 'primary', 
  className = '',
  children = 'Schedule a Demo'
}: DemoButtonProps) {
  const navigate = useNavigate();

  const baseStyles = "px-8 py-4 rounded-full text-lg font-semibold transition-colors inline-flex items-center";
  const variantStyles = variant === 'primary' 
    ? "bg-[#00ccfe] text-white hover:bg-[#00ccfe]/90"
    : "bg-white text-[#00ccfe] hover:bg-gray-100";

  return (
    <button
      onClick={() => navigate('/schedule-demo')}
      className={`${baseStyles} ${variantStyles} ${className}`}
    >
      {children}
    </button>
  );
}