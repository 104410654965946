import React from 'react';
import { Box, Code, GraduationCap } from 'lucide-react';

const offerings = [
  {
    icon: Box,
    title: 'Medical Products',
    description: 'High-quality and reliable products tailored for healthcare professionals.',
    features: ['Premium IV Solutions', 'Diagnostic Tests', 'Medical Supplies']
  },
  {
    icon: Code,
    title: 'SyncCare360 Software',
    description: 'Innovative software solution designed to enhance patient care and streamline workflows.',
    features: ['Patient Management', 'Care Coordination', 'Analytics Dashboard']
  },
  {
    icon: GraduationCap,
    title: 'Training and Education',
    description: 'Expert-led courses including the Wound Care Playbook and CEU courses.',
    features: ['Wound Care Certification', 'CEU Programs', 'Professional Development']
  }
];

export default function CoreOffering() {
  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Comprehensive Healthcare Solutions
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Everything you need to deliver exceptional patient care
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {offerings.map((offering) => (
            <div key={offering.title} className="bg-white p-8 rounded-xl shadow-lg">
              <offering.icon className="h-12 w-12 text-[#00ccfe] mb-6" />
              <h3 className="text-2xl font-semibold mb-4">{offering.title}</h3>
              <p className="text-gray-600 mb-6">{offering.description}</p>
              <ul className="space-y-3">
                {offering.features.map((feature) => (
                  <li key={feature} className="flex items-center text-gray-600">
                    <span className="h-2 w-2 bg-[#00ccfe] rounded-full mr-3"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}