import React from 'react';

export default function SyncCareBenefits() {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-6">Transform Your Practice</h2>
            <p className="text-xl text-gray-600 mb-8">
              SyncCare360 helps healthcare providers improve efficiency, reduce costs, 
              and deliver better patient outcomes.
            </p>
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="h-6 w-6 rounded-full bg-[#00ccfe]/20 flex items-center justify-center mr-3 mt-1">
                  <span className="h-3 w-3 rounded-full bg-[#00ccfe]"></span>
                </span>
                <div>
                  <h3 className="font-semibold mb-1">Increased Efficiency</h3>
                  <p className="text-gray-600">Automate routine tasks and streamline workflows</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="h-6 w-6 rounded-full bg-[#00ccfe]/20 flex items-center justify-center mr-3 mt-1">
                  <span className="h-3 w-3 rounded-full bg-[#00ccfe]"></span>
                </span>
                <div>
                  <h3 className="font-semibold mb-1">Better Patient Care</h3>
                  <p className="text-gray-600">Improve care coordination and patient outcomes</p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="h-6 w-6 rounded-full bg-[#00ccfe]/20 flex items-center justify-center mr-3 mt-1">
                  <span className="h-3 w-3 rounded-full bg-[#00ccfe]"></span>
                </span>
                <div>
                  <h3 className="font-semibold mb-1">Data-Driven Decisions</h3>
                  <p className="text-gray-600">Make informed decisions with powerful analytics</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&w=800&q=80"
              alt="Healthcare professional using SyncCare360"
              className="rounded-xl shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}