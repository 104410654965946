import React from 'react';
import { ShieldCheck, Truck, HeartPulse } from 'lucide-react';

const features = [
  {
    icon: ShieldCheck,
    title: 'Quality Assured',
    description: 'All products undergo rigorous testing and meet the highest quality standards'
  },
  {
    icon: Truck,
    title: 'Fast Delivery',
    description: 'Efficient shipping and handling to ensure your supplies arrive when needed'
  },
  {
    icon: HeartPulse,
    title: 'Patient Safety',
    description: 'Products designed with patient safety and comfort in mind'
  }
];

export default function ProductFeatures() {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Why Choose Our Products</h2>
          <p className="text-xl text-gray-600">
            Committed to delivering excellence in medical supplies
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature) => (
            <div key={feature.title} className="text-center">
              <div className="bg-[#00ccfe]/10 rounded-full p-6 inline-block mb-6">
                <feature.icon className="h-8 w-8 text-[#00ccfe]" />
              </div>
              <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}