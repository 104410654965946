import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ProductHero from '../components/products/ProductHero';
import ProductList from '../components/products/ProductList';
import ProductFeatures from '../components/products/ProductFeatures';
import CallToAction from '../components/landing/CallToAction';

export default function ProductsPage() {
  return (
    <>
      <Navbar />
      <ProductHero />
      <ProductList />
      <ProductFeatures />
      <CallToAction />
      <Footer />
    </>
  );
}