import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import DemoButton from '../components/common/DemoButton';
import CoreOffering from '../components/landing/CoreOffering';
import AboutSection from '../components/landing/AboutSection';
import Testimonials from '../components/landing/Testimonials';
import CallToAction from '../components/landing/CallToAction';

export default function LandingPage() {
  return (
    <>
      <Navbar />
      
      {/* Hero Section */}
      <section className="pt-20 bg-gradient-to-b from-[#00ccfe]/5 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
              Empowering Healthcare Providers
            </h1>
            <p className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
              Discover our comprehensive suite of medical products, innovative software solutions, 
              and expert-led training programs designed to enhance patient care.
            </p>
            <div className="space-x-4">
              <DemoButton variant="primary">Schedule a Consultation</DemoButton>
              <DemoButton variant="secondary">Learn More</DemoButton>
            </div>
          </div>
        </div>
      </section>

      <CoreOffering />
      <AboutSection />
      <Testimonials />
      <CallToAction />
      
      <Footer />
    </>
  );
}