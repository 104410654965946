import React from 'react';
import { Star } from 'lucide-react';

const testimonials = [
  {
    quote: "BWell Company's products and software have transformed how we deliver care. The support team is exceptional.",
    author: "Dr. Sarah Johnson",
    role: "Medical Director",
    organization: "Premier Care Center"
  },
  {
    quote: "The Wound Care Playbook training has become an essential resource for our nursing staff.",
    author: "Michael Chen",
    role: "Director of Nursing",
    organization: "Healthcare Solutions"
  },
  {
    quote: "SyncCare360 has streamlined our workflows and improved patient outcomes significantly.",
    author: "Dr. Emily Martinez",
    role: "Chief Medical Officer",
    organization: "Advanced Medical Group"
  }
];

export default function Testimonials() {
  return (
    <section className="py-24 bg-[#00ccfe]/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900 mb-16">
          Trusted by Healthcare Leaders
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial) => (
            <div key={testimonial.author} className="bg-white p-8 rounded-xl shadow-lg">
              <div className="flex mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-[#00ccfe] fill-current" />
                ))}
              </div>
              <p className="text-gray-600 mb-6">"{testimonial.quote}"</p>
              <div>
                <p className="font-semibold">{testimonial.author}</p>
                <p className="text-sm text-gray-500">{testimonial.role}</p>
                <p className="text-sm text-gray-500">{testimonial.organization}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}