import React from 'react';
import { BookOpen, Clock, Award } from 'lucide-react';

const courses = [
  {
    title: 'Wound Care Playbook',
    description: 'Comprehensive wound care certification program',
    duration: '40 hours',
    certification: 'Professional Certification',
    features: ['Expert instruction', 'Hands-on practice', 'CEU credits']
  },
  {
    title: 'IV Therapy Essentials',
    description: 'Master IV therapy techniques and best practices',
    duration: '20 hours',
    certification: 'Skill Certificate',
    features: ['Clinical procedures', 'Safety protocols', 'Case studies']
  },
  {
    title: 'Healthcare Leadership',
    description: 'Develop essential leadership skills for healthcare',
    duration: '30 hours',
    certification: 'Management Certificate',
    features: ['Team management', 'Quality improvement', 'Strategic planning']
  }
];

export default function CourseList() {
  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Available Courses</h2>
          <p className="text-xl text-gray-600">
            Enhance your skills with our professional training programs
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {courses.map((course) => (
            <div key={course.title} className="bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">{course.title}</h3>
              <p className="text-gray-600 mb-6">{course.description}</p>
              
              <div className="flex items-center mb-4">
                <Clock className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span className="text-gray-600">{course.duration}</span>
              </div>
              
              <div className="flex items-center mb-6">
                <Award className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span className="text-gray-600">{course.certification}</span>
              </div>

              <ul className="space-y-3">
                {course.features.map((feature) => (
                  <li key={feature} className="flex items-center text-gray-600">
                    <BookOpen className="h-4 w-4 text-[#00ccfe] mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}