import React, { useEffect } from 'react';

export default function CalendarEmbed() {
  useEffect(() => {
    // Load the form embed script
    const script = document.createElement('script');
    script.src = 'https://link.msgsndr.com/js/form_embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-gray-50 rounded-2xl p-8">
        <h2 className="text-3xl font-bold text-center mb-8">Schedule Your Demo</h2>
        <div className="aspect-video">
          <iframe 
            src="https://api.leadconnectorhq.com/widget/booking/N4QOGLnXFzeoXjYgMs7D" 
            style={{ width: '100%', border: 'none', overflow: 'hidden' }}
            scrolling="no"
            id="N4QOGLnXFzeoXjYgMs7D_1734364808735"
            title="Schedule Demo Calendar"
          />
        </div>
      </div>
    </div>
  );
}