import React from 'react';
import DemoButton from '../common/DemoButton';

export default function SyncCareHero() {
  return (
    <section className="pt-20 bg-gradient-to-b from-[#00ccfe]/5 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
            SyncCare360
          </h1>
          <p className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
            A comprehensive healthcare management platform that streamlines workflows,
            enhances patient care, and improves operational efficiency.
          </p>
          <DemoButton>Schedule Software Demo</DemoButton>
        </div>
      </div>
    </section>
  );
}