import React from 'react';
import DemoButton from '../common/DemoButton';

export default function CallToAction() {
  return (
    <section className="py-24 bg-[#00ccfe]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
          Ready to Transform Your Healthcare Practice?
        </h2>
        <p className="text-xl text-white/90 mb-12 max-w-3xl mx-auto">
          Schedule a personalized demo to see how BWell Company can help you deliver better patient care.
        </p>
        <DemoButton variant="secondary" className="shadow-lg">
          Get Started Today
        </DemoButton>
      </div>
    </section>
  );
}