import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import HomePage from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import SyncCarePage from './pages/SyncCarePage';
import TrainingPage from './pages/TrainingPage';
import HealgenSalesPage from './pages/HealgenSalesPage';
import FleboflexPage from './pages/FleboflexPage';
import DemoPage from './pages/DemoPage';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/synccare360" element={<SyncCarePage />} />
        <Route path="/training" element={<TrainingPage />} />
        <Route path="/care-homes" element={<HomePage />} />
        <Route path="/healgen" element={<HealgenSalesPage />} />
        <Route path="/fleboflex" element={<FleboflexPage />} />
        <Route path="/schedule-demo" element={<DemoPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}