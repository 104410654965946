import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SyncCareHero from '../components/synccare/SyncCareHero';
import SyncCareFeatures from '../components/synccare/SyncCareFeatures';
import SyncCareBenefits from '../components/synccare/SyncCareBenefits';
import CallToAction from '../components/landing/CallToAction';

export default function SyncCarePage() {
  return (
    <>
      <Navbar />
      <SyncCareHero />
      <SyncCareFeatures />
      <SyncCareBenefits />
      <CallToAction />
      <Footer />
    </>
  );
}