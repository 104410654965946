import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TrainingHero from '../components/training/TrainingHero';
import CourseList from '../components/training/CourseList';
import TrainingBenefits from '../components/training/TrainingBenefits';
import CallToAction from '../components/landing/CallToAction';

export default function TrainingPage() {
  return (
    <>
      <Navbar />
      <TrainingHero />
      <CourseList />
      <TrainingBenefits />
      <CallToAction />
      <Footer />
    </>
  );
}