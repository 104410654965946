import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import DemoFeatures from '../components/demo/DemoFeatures';
import CalendarEmbed from '../components/demo/CalendarEmbed';

export default function DemoPage() {
  return (
    <>
      <Navbar />
      
      {/* Hero Section */}
      <section className="pt-20 bg-gradient-to-b from-[#00ccfe]/5 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Let's Transform Your Healthcare Practice Together
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
              Schedule a personalized consultation with our healthcare solutions experts to discover how BWell Company can enhance your practice.
            </p>
          </div>
          
          <DemoFeatures />
        </div>
      </section>

      {/* Calendar Section */}
      <section className="py-20 bg-white">
        <CalendarEmbed />
      </section>

      <Footer />
    </>
  );
}