import React from 'react';
import { Users, BarChart2, Clock, Shield, MessageSquare, Settings } from 'lucide-react';

const features = [
  {
    icon: Users,
    title: 'Patient Management',
    description: 'Streamline patient records and care coordination'
  },
  {
    icon: BarChart2,
    title: 'Analytics Dashboard',
    description: 'Data-driven insights for better decision making'
  },
  {
    icon: Clock,
    title: 'Scheduling',
    description: 'Efficient appointment and resource management'
  },
  {
    icon: Shield,
    title: 'HIPAA Compliant',
    description: 'Secure and compliant data handling'
  },
  {
    icon: MessageSquare,
    title: 'Communication',
    description: 'Integrated messaging and notifications'
  },
  {
    icon: Settings,
    title: 'Customizable',
    description: 'Flexible workflows that adapt to your needs'
  }
];

export default function SyncCareFeatures() {
  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Powerful Features</h2>
          <p className="text-xl text-gray-600">
            Everything you need to manage your healthcare practice
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature) => (
            <div key={feature.title} className="bg-white p-8 rounded-xl shadow-lg">
              <feature.icon className="h-8 w-8 text-[#00ccfe] mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}