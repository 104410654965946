import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Image from './Image';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { label: 'Medical Products', href: '/products' },
    { label: 'SyncCare360', href: '/synccare360' },
    { label: 'Training', href: '/training' },
  ];

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="h-12">
              <Image name="logo" alt="BWell Company Logo" className="h-full w-auto" />
            </Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map((item) => (
              <Link
                key={item.label}
                to={item.href}
                className="text-gray-700 hover:text-[#00ccfe] transition-colors"
              >
                {item.label}
              </Link>
            ))}
            <button 
              onClick={() => navigate('/schedule-demo')}
              className="bg-[#00ccfe] text-white px-6 py-2 rounded-full hover:bg-[#00ccfe]/90 transition-colors"
            >
              Schedule a Consultation
            </button>
          </div>

          <div className="md:hidden flex items-center">
            <button onClick={() => setIsOpen(!isOpen)} className="text-gray-700">
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {menuItems.map((item) => (
              <Link
                key={item.label}
                to={item.href}
                className="block px-3 py-2 text-gray-700 hover:text-[#00ccfe] transition-colors"
                onClick={() => setIsOpen(false)}
              >
                {item.label}
              </Link>
            ))}
            <button 
              onClick={() => {
                navigate('/schedule-demo');
                setIsOpen(false);
              }}
              className="w-full text-left px-3 py-2 text-white bg-[#00ccfe] rounded-md"
            >
              Schedule a Consultation
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}