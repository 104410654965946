import React from 'react';
import { Droplet, Activity, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const products = [
  {
    icon: Droplet,
    name: 'Fleboflex® IV Solutions',
    description: 'Premium IV solutions with innovative packaging for safe and efficient delivery.',
    features: ['PVC & Latex-free', 'Multiple size options', 'USP Class VI certified'],
    link: '/fleboflex'
  },
  {
    icon: Activity,
    name: 'Healgen® Diagnostic Tests',
    description: '3-in-1 COVID-19 & Flu testing solutions for rapid, accurate results.',
    features: ['15-minute results', 'High accuracy', 'FDA authorized'],
    link: '/healgen'
  },
  {
    icon: Shield,
    name: 'Medical Supplies',
    description: 'Essential medical supplies designed for healthcare professionals.',
    features: ['Premium quality', 'Bulk ordering available', 'Fast shipping']
  }
];

export default function ProductList() {
  const navigate = useNavigate();

  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Our Product Line</h2>
          <p className="text-xl text-gray-600">
            Discover our range of high-quality medical products
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {products.map((product) => (
            <div key={product.name} className="bg-white p-8 rounded-xl shadow-lg">
              <product.icon className="h-12 w-12 text-[#00ccfe] mb-6" />
              <h3 className="text-2xl font-semibold mb-4">{product.name}</h3>
              <p className="text-gray-600 mb-6">{product.description}</p>
              <ul className="space-y-3 mb-6">
                {product.features.map((feature) => (
                  <li key={feature} className="flex items-center text-gray-600">
                    <span className="h-2 w-2 bg-[#00ccfe] rounded-full mr-3"></span>
                    {feature}
                  </li>
                ))}
              </ul>
              {product.link && (
                <button
                  onClick={() => navigate(product.link)}
                  className="w-full bg-[#00ccfe] text-white px-6 py-3 rounded-full hover:bg-[#00ccfe]/90 transition-colors"
                >
                  Learn More
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}